const BetsModule = () => import(/* webpackChunkName: "account-module" */ './views/Modules.vue');
const BetHistory = () => import(/* webpackChunkName: "account-module" */ '../bets/views/BetHistory.vue');
const CasinoBetHistory = () => import(/* webpackChunkName: "account-module" */ '../bets/views/CasinoReportHistory.vue');



const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: '/bet-history',
            name: 'bet-history',
            component: BetHistory,
        },
        {
            path: '/casino-report-history',
            name: 'casino-report-history',
            component: CasinoBetHistory,
        },

    ],
}

export default BetsRoutes;