import axios from 'axios'
import { headers } from '@/shared/services/headers'
import { TIME_5000 } from '@/shared/constants/services-const';
import handlerErrorFromApis from '@/shared/services/error-handler';

const instance = axios.create({
	baseURL: process.env.VUE_APP_AUTH_API,
	timeout: TIME_5000,
	headers: headers
})

instance.interceptors.response.use(function (response) {
	return Promise.resolve(response);
}, function (error) {
	if (error) {
		if (error?.response?.status == 422) {
			if (error.response.data.details)
				return Promise.reject(error.response.data.details[Object.keys(error.response.data.details)[0]]);
			else
				return Promise.reject([error.response.data.message]);
		}
		else if (error?.response?.status == 401) {
			handlerErrorFromApis(error);
		}
		else if (error?.response?.status == 400) {
			return Promise.reject([error?.response.data.message]);
		}
		else {
			return Promise.reject(error?.response);
		}
	}

});

export default instance;