<template>
    <section class="no-notifucation">
        <div class="container">
            <div class="no-notifucation-icon">
                <img src="images/no-data-img.webp" alt="">
            </div>
            <div class="thm-heading">
                <h3 class="profit-color">{{translatedLangData('no-data-found', 'No data found')}}!</h3>
                <p>{{translatedLangData('cant-find-any-item', `We can't find any item matching your search.`)}}</p>
            </div>
            <!-- <div class="thm-heading mt-lg-4 mt-2 data-btn">
                <a href="Javascript:void(0);" class="thm-but ">Go back </a>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    name: "NoDataFound",
    inject: ['translatedLangData'],
}
</script>