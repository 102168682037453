const state = {
    defaultcoll: 'col-md-10',
};
const getters = {
    isdefaultcoll: state => state?.defaultcoll, 
};
const actions = {
    setUser ({ commit},defaultcoll) {
        commit('setmenu',defaultcoll);
    }
};
const mutations = {
    setmenu(state, defaultcoll) {
        state.defaultcoll = defaultcoll
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};