const CasinSlotModule = () => import(/* webpackChunkName: "casino-slot-module" */ './views/Module.vue');
const CasinoSlot = () => import(/* webpackChunkName: "casino-slot" */ './views/CasinoSlot.vue');

const CasinoSlotRoutes = {
    path: '/',
    component: CasinSlotModule,
    children: [
        {
            path: 'live-casino',
            name: 'live-casino',
            component: CasinoSlot
        },
        {
            path: 'slots',
            name: 'slots',
            component: CasinoSlot
        },
    ],
}

export default CasinoSlotRoutes;