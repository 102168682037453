<template>
    <div class="ms-container">
        <div class="recent-bank-sec">
            <div class="recent-bank">
                <div class="bank-icon">
                    <!-- <img src="@/assets/images/right-icon.webp" alt="" /> -->
                </div>
                <div class="bank-con">
                    <h6>Success!</h6>
                    <span>{{ message }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
    mounted() {
    }
}
</script>