const OthersModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Others = () => import(/* webpackChunkName: "games" */ './views/Others.vue');

const OthersRoutes = {
    path: '/',
    component: OthersModule,
    children: [
        {
            path: 'fantasy',
            name: 'fantasy',
            component: Others
        },
        {
            path: 'evolution',
            name: 'evolution',
            component: Others
        },
        {
            path: 'our-casino',
            name: 'our-casino',
            component: Others
        },
    ],
}

export default OthersRoutes;