const ReferAndEarnModule = () => import(/* webpackChunkName: "account-module" */ './views/Modules.vue');
const ReferAndEarn = () => import(/* webpackChunkName: "account-module" */ '../refer-and-earn/views/ReferAndEarn.vue');

const ReferAndEarnRoutes =   {
    path: '/',
    component: ReferAndEarnModule,
    children: [
     
        {
            path: '/refer-earn',
            name: 'refer-earn',
            component: ReferAndEarn,
          },
      
    ],
  }

export default ReferAndEarnRoutes;