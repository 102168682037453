const virtualModule = () => import('../virtual-sport/view/Module.vue');
const VirtualSport = () => import('../virtual-sport/view/virtualSports.vue');

const VirtualRoutes =   {
    path: '/',
    component: virtualModule,
    children: [
     
        {
            path: '/virtual-sports',
            name: 'virtual-sports',
            component: VirtualSport,
          },
      
    ],
  }

export default VirtualRoutes;