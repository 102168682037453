<template>
        <div class="ms-container">
        <div class="recent-bank-sec bg-wrong">
            <div class="recent-bank">
                <div class="bank-icon">
                    <img src="@/assets/images/right-icon.webp" alt="" />
                </div>
                <div class="bank-con">
                    <h6>Wrong!</h6>
                    <span>{{ message }}</span>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: "ErrorModal",
    props: ['message'],
}
</script>