<template>
        <div class="modal-content">
            <div class="modal-header">
                <div class="game-header-content">
                    <div class="game-bak-close-block">
                        <a href="javascript:void(0);" ref="close_button" data-bs-dismiss="modal">
                            <i class="fa-sharp fa-solid fa-house-chimney mobile-logo"></i>
                        </a> 
                        <strong>{{ gameItem?.game_name }}</strong>
                    </div>
                    <div class="close-game-btn">
                        <a href="javascript:void(0);" data-bs-dismiss="modal"><img src="@/assets/images/close.webp" alt=""></a>
                    </div>
                </div>
            </div>
            <div class="modal-body game-body">
                <div class="game-iframe-sec">
                    <div v-if="this.iFrameUrl" class="iframe-content">
                        <iframe :src="this.iFrameUrl" frameborder="0"
                            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                            height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            title="Tr&amp;eacute;ning TEXT"></iframe>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import { getGameItem } from '@/shared/constants/shared-data';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'IFrameModal',
    data() {
        return {
            loading: false,
            iFrameUrl: null,
            gameItem: null,
        }
    },
    mounted() {
        this.gameItem = this.getGameItemHere();
        if (this.gameItem.provider == 'poker') {
            this.getLivePockerUrl()
        }
        else {
            this.getDreamCasinoLoadGames()
        }
    },
    methods: {
        getLivePockerUrl() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                        this.showSuccessModalFunc(response.data.message)
                        this.closeModal();
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },

        getDreamCasinoLoadGames() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + this.gameItem.game_code +'/'+ this.gameItem.sm_id, {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error In Iframe Modal : ", error);
                    this.showErrorModalFunc(error);
                    this.closeModal()
                }
            });
        },

        closeModal() {
            setTimeout(() => {
                this.$refs.close_button.click();
            }, 400);
        },

        getGameItemHere() {
            return getGameItem();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>