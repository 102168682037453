<template>

    <!-- <div class="select-language-sec">
        <div class="modal fade" id="select-language-btn" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="language-header">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalToggleLabel">
                            </h5>
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/images/cancel.webp" alt="">
                            </button>
                        </div>
                    </div>
                    <div class="select-language-body">
                        <div class="modal-body">
                            <div class="language-icons">
                                <img src="@/assets/images/language-icon.webp" alt="">
                            </div>
                            <div class="lang-head">
                                <h4>Change Language</h4>
                            </div>
                            <div id="app_1"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->

    <div class="modal fade change-lang-modal" id="select-language-btn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{translatedLangData('change-language', 'Change Your Language')}}</h3>
                    </div>
                    <button class="btn-close" type="button" data-bs-dismiss="modal">
                        <!-- <img src="@/assets/images/cancel.webp" alt=""> -->
                    </button>
                </div>
                <div class="modal-body">
                    <section class="sub-header-wrapper">

                        <div class="langauage-content">
                            <div class="search-lang-input">
                                <input type="search" :placeholder="translatedLangData('search-key', 'Search')"  v-model="searchTerm" class="form-control"> <button class="btn link-btn">
                                    <img src="@/assets/images/search-grey-icon.webp" alt="search">
                                </button>
                            </div>
                            <div class="custom-language-list">
                                <ul>
                                    <li v-for="item in filteredLanguages" :key="item.lang_code"
                                        :class="{ 'active': selectedValue.lang_code == item.lang_code  }">
                                        <div class="form-check" @click="this.selectedValue = item">
                                            <label class="form-check-label">
                                                {{ item.lang_name }}
                                            </label>
                                        </div>
                                    </li>                       
                                </ul>
                            </div>
                            </div>
                            <div class="two-btns">
                                <ul class="cmn-ul-list">
                                    <li><button class="btn thm-bor-but cancel-lang-btn" data-bs-dismiss="modal" @click="closeLangModal">{{translatedLangData('close', 'Close')}}</button></li>
                                    <li><button class="btn thm-but" @click="selectedlanguage" data-bs-dismiss="modal">{{translatedLangData('update', 'Update')}}</button></li>
                                </ul> 
                            </div>
                            <!-- <div id="app_1"> </div> -->
                    </section>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import LanguageService from '@/shared/services/language-service.js'
export default {
    name: 'LanguageModal',
    inject:['translatedLangData'],
    data() {
        return {
            selectedValue: '',
            searchTerm: ''
        }
    },
    mounted(){
        if(this.$store.getters.languageSelected==null){
            this.$store.dispatch('setLanguageSelected', {lang_code: "en"} );
        }
        this.selectedValue=this.$store.getters.languageSelected
        
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        languagesData() {
            return this.$store.getters.languagesData;
        },
        filteredLanguages() {
            if(this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData
        },

    },
    methods: {
        closeLangModal(){
            this.selectedValue=this.$store.getters.languageSelected
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        async selectedlanguage() {
            if (this.selectedValue && this.selectedValue.lang_code === 'en') {
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_code);
            this.$store.dispatch('setLanguageSelected', this.$store.getters.languagesData[0]);
            this.$parent.$parent.langData = "";
            this.showSuccessModalFunc('language updated successfully');
            document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
        } else if (this.selectedValue) {
            const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url;
            this.$parent.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
            if (this.$parent.$parent.langData) {
                localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                this.$store.dispatch('setLanguageSelected', this.selectedValue);
                this.showSuccessModalFunc('language updated successfully');
                document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            } else {
                localStorage.removeItem('selectedLanguageUrl');
                this.showErrorModalFunc('language data is not available');
            }
        }
        },

    },
}
</script>

<style></style>
