<template>
    <section class="chat-modal-btn-sec">
        <button class="chat-modal-btn" data-bs-toggle="modal" data-bs-target="#usernamemodal" @click="toggleChatModal()"
            ref="chatOpenCloaseBtn">
            <div class="chat-modal-icon">
                <img src="@/assets/images/chat-icon.webp" alt="chat btn">
            </div>
        </button>
        
    </section>
    <!-- <div class="back-to-top thm-but"
			:class="{ 'd-none': scY < 200, 'bottom-10': !($route.name == 'Home' || $route.name == 'my-market' || ($route.name == 'games' && $route.params.type == 'GAMES')) }"
			@click="goToTop()">
			<img src="@/assets/images/arow-up-white.webp" alt="">
		</div> -->
    <div class="modal fade" id="usernamemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        data-bs-keyboard="false" >
        <div class="modal-dialog  thm-chat-modal-sec">
            <div class="modal-content">
                <iframe id="chat_modal" :src="iframeUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%"
                    height="100%" scrolling="no" allowfullscreen="allowfullscreen" v-if="showChat"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'ChatComponent',
    emits: ['error-modal','success-modal'],
    data() {
        return {
            showChat: false,
            user_id: null,
            iframeUrl: null,
        }
    },
    computed: {
        userName() {
            return this.$store.getters.stateUser?.userid || null;
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        }
    },
    mounted() {
        const guestUserName = localStorage.getItem('guestUserName')
        if (guestUserName && !this.userName && !this.checkIsLogin) {
            this.user_id = guestUserName;
        } else {
            this.user_id = this.userName
        }
    },
    methods: {
        async toggleChatModal() {
            const headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            };

            const userid = this.user_id;
            const apiEndpoint = this.checkIsLogin ? apiName.GET_CHATBOT_IFRAME : apiName.GET_CHATBOT_IFRAME_GUEST;

            const requestData = userid && !this.checkIsLogin ? { userid } : {};

            api.post(apiEndpoint, requestData, { headers })
                .then(res => {
                    window.addEventListener('message', this.handleMessage);
                    this.iframeUrl = res.data.chat_bot_url;
                })
                .catch(error => {
                    this.showErrorModalFunc(error);
                });

            this.showChat = !this.showChat;
        },
        handleMessage(event) {
            if (event.data.userName) {
                this.user_id = event.data.userName;
                localStorage.setItem('guestUserName', this.user_id)
            }
            if (event.data === 'closeChatModal') {
                this.$refs.chatOpenCloaseBtn.click();
                window.removeEventListener('message', this.handleMessage);
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }

}
</script>

<style>
.chat-back-arrow {
    height: 15px;
    vertical-align: text-top;
}

@media screen and (max-width: 575px) {
    .modal-dialog.thm-chat-modal-sec {
        margin: 0 !important;
    }
}

/* .modal-backdrop {
    z-index: 0 !important;
} */
</style>