const BonusModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const Bonus = () => import(/* webpackChunkName: "account-module" */ '../bonus/views/Bonus.vue');

const BonusRoutes =   {
    path: '/',
    component: BonusModule,
    children: [
     
        {
            path: '/bonus',
            name: 'bonus',
            component: Bonus,
          },
      
    ],
  }

export default BonusRoutes;