<template>	
	<div class="exposure">
		<div class="modal fade mymarket-modal-box setbutton-value-wrapper" id="buttonvalue" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">
					{{translatedLangData('set-button-values', 'Set Button Values')}}
                  </h5>
                  <button type="button" class="btn-close my-market-close-btn" data-bs-dismiss="modal" aria-label="Close"
                     ref="closeMyMarket" @click="resetChips">
                  </button>
               </div>
               <div class="modal-body">
				<div class="change-btn-value">
					<form action="">
						<div class="row" v-if="chips">
							<div class="col-6 col-md-6 col-lg-3">
								<h2 class="price">{{translatedLangData('price-label', 'Price Label')}}</h2>
								<ul class="change-value-btn-lft">
									<li>
										<input type="tel" v-model="chips.chip_name_1" maxlength="8" @input="numberFormat('chip_name_1'),checkLimit('chip_name_1')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_2" maxlength="8" @input="numberFormat('chip_name_2'),checkLimit('chip_name_2')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_3" maxlength="8" @input="numberFormat('chip_name_3'),checkLimit('chip_name_3')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_4" maxlength="8" @input="numberFormat('chip_name_4'),checkLimit('chip_name_4')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_5" maxlength="8" @input="numberFormat('chip_name_5'),checkLimit('chip_name_5')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_6" maxlength="8" @input="numberFormat('chip_name_6'),checkLimit('chip_name_6')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_7" maxlength="8" @input="numberFormat('chip_name_7'),checkLimit('chip_name_7')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
									<li>
										<input type="tel" v-model="chips.chip_name_8" maxlength="8" @input="numberFormat('chip_name_8'),checkLimit('chip_name_8')" @keypress="checkForNonNumericals($event)" class="form-control" />
									</li>
								</ul>
							</div>
							<div class="col-6 col-md-6 col-lg-3">
								<h2 class="price px-1">{{translatedLangData('price-value', 'Price Value')}}</h2>
								<ul class="change-value-btn-rgt">
									<li>
										<input type="tel" @input="numberFormat('chip_val_1'),checkLimit('chip_val_1')" @keypress="checkForNonNumericals($event)"
											v-model="chips.chip_val_1" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_2'),checkLimit('chip_val_2')" @keypress="checkForNonNumericals($event)"
                                        v-model="chips.chip_val_2" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_3'),checkLimit('chip_val_3')" @keypress="checkForNonNumericals($event)"
                                        v-model="chips.chip_val_3" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_4'),checkLimit('chip_val_4')" @keypress="checkForNonNumericals($event)"
                                        v-model="chips.chip_val_4" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_5'),checkLimit('chip_val_5')" @keypress="checkForNonNumericals($event)"
                                        v-model="chips.chip_val_5" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_6'),checkLimit('chip_val_6')" @keypress="checkForNonNumericals($event)"
                                        v-model="chips.chip_val_6" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_7'),checkLimit('chip_val_7')" @keypress="checkForNonNumericals($event)"
                                        	v-model="chips.chip_val_7" maxlength="8" class="form-control" />
									</li>
									<li>
										<input type="tel" @input="numberFormat('chip_val_8'),checkLimit('chip_val_8')" @keypress="checkForNonNumericals($event)"
                                        	v-model="chips.chip_val_8" maxlength="8" class="form-control" />
									</li>
								</ul>
							</div>
							<div class="col-12">
								<button class="btn btn-primary" @click.prevent="callChangeStakeValue()">{{translatedLangData('update', 'Update')}}</button>
							</div>
						</div>
					</form>
				</div>
               </div>
            </div>
         </div>
      	</div>
	</div>
</template>

<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { kFormatter } from '@/shared/utils/formatter';
//import SideMenu from '@/shared/components/header/SideMenu.vue';

export default {
	name: 'SetValue',
	// components: { SideMenu },
    inject: ['translatedLangData'],
	data() {
		return {
			// chips: null,
			chips:JSON.parse(JSON.stringify(this.$store.getters.chips)),
			chipsDummy: null,
			userData: null
		}
	},
	mounted() {
		this.chips = { ...this.$store?.getters?.chips };
		this.chipsDummy = { ...this.$store?.getters?.chips };
		this.userData = this.$store?.getters?.stateUser;
	},	
	methods: {
		hasDuplicates() {
			// Separate keys related to 'chip_name' and 'chip_val'
			const chipNameKeys = Object.keys(this.chips).filter(key => key.startsWith('chip_name'));
			const chipValKeys = Object.keys(this.chips).filter(key => key.startsWith('chip_val'));
			const chipNameValues = chipNameKeys.map(key => parseInt(this.chips[key]));
			const chipValValues = chipValKeys.map(key => parseInt(this.chips[key]));
			const dupChipNameValues = new Set(chipNameValues);
			const dupChipValValues = new Set(chipValValues);			
			const hasValueLessThan100 = chipNameValues.some(value => parseInt(value) < 100) || chipValValues.some(value => parseInt(value) < 100);
			if (hasValueLessThan100) {
				this.showErrorModalFunc(this.translatedLangData('less-100-not', 'Values Less Than 100 Are Not Allowed'));
				return true;
			}
			else if(chipNameValues.length !== dupChipNameValues.size && chipValValues.length !== dupChipValValues.size){
				this.showErrorModalFunc(this.translatedLangData('dup-val-label-not', 'Duplicate Price Values And Duplicate Price Labels Are Not Allowed'));
				return true
			}
			else if(chipNameValues.length === dupChipNameValues.size && chipValValues.length !== dupChipValValues.size){
				this.showErrorModalFunc(this.translatedLangData('dup-val-not', 'Duplicate Price Values Are Not Allowed'));
				return true
			}
			else if(chipNameValues.length !== dupChipNameValues.size && chipValValues.length === dupChipValValues.size){
				this.showErrorModalFunc(this.translatedLangData('dup-label-not', 'Duplicate Price Labels Are Not Allowed'));
				return true
			}
			else{
				return false
			}
		},
		numberFormat(prop) {
            // Convert the input to a string
            if (this.chips[prop] != '') {
                let stringValue=''
                if(typeof this.chips[prop]!='string'){
                    stringValue = this.chips[prop].toString();
                }
                else{
                    stringValue = this.chips[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.chips[prop]!='string'){
                    this.chips[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.chips[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
		checkLimit(index) {   
			// if(this.chips[index]<100){
			// 	this.showErrorModalFunc('Value Should Be Minimum 100')
			// }     
            this.chips[index] = Math.max(0, Math.min(10000000, this.chips[index]));
        },
		callChangeStakeValue() {
			if (!this.hasDuplicates()) {
				let chipsData = {
					"chips": {
						...this.chips
					}
				}
				let headers = {
					'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				}
				// this.loading = true;
				this.$store.dispatch('setGlobalLoader', true);
				api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
					// this.loading = false;
					this.$store.dispatch('setGlobalLoader', false);
					if (response && response.status == 200) {
						this.$store.dispatch('setChips', this.chips);
						this.showSuccessModalFunc('Saved successfully.')
						this.$emit('save-btn');
						this.chips=JSON.parse(JSON.stringify(this.$store.getters.chips))
						this.$refs.closeMyMarket.click();
					} else {
						
					}
				}).catch(error => {
					// this.loading = false;
					this.$store.dispatch('setGlobalLoader', false);
					if (error) {
						this.showErrorModalFunc(error[0]);
					}
				});
			}
			
		},
		getKFormatter(num, name) {
			let minStake = 100;
			let maxStake = 10000000;

			if (num < minStake || isNaN(num)) {
				this.showErrorModalFunc(this.translatedLangData('stake-100-1cr', 'Stake must be between 100 and 1Cr.'));
				this.chips[name] = minStake;
			} else if (num > maxStake) {
				this.showErrorModalFunc(this.translatedLangData('stake-100-1cr', 'Stake must be between 100 and 1Cr.'));
				this.chips[name] = maxStake;
			} else {
				this.chips[name] = num;
			}
			return kFormatter(this.chips[name]);
		},
		resetChips() {
			this.chips = { ...this.$store?.getters?.chips };
		},
		showErrorModalFunc(message) {
			this.$emit('error-modal', message);
		},
		showSuccessModalFunc(message) {
			this.$emit('success-modal', message);
		},
	},
	emits: ['close-stake-modal', 'success-modal', 'error-modal', 'save-btn']

}
</script>

<style></style>