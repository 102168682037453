
const Matka = () => import ('./views/matka.vue');
const Matkadetails = () => import('./views/matkaDeatils.vue');
const MatkaModule = () => import(/* webpackChunkName: "matka-module" */ './views/Module.vue'); 

const matkaRoutes = {
    path: '/',
    component: MatkaModule,
    name: 'MatkaModule',
    children: [
      {
        path: '/matka',
        component: Matka,
        name: 'Matka',
      },
      {
        path: '/matka-details/:itemDetail',
        name: 'matka-details',
        component: Matkadetails,
      },

    ],
  }

export default matkaRoutes;