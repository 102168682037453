const CricketFightModule  = () => import(/* webpackChunkName: "my-market-module" */ './views/module.vue');
const cricketFightMatch  = () => import(/* webpackChunkName: "my-market-module" */ './views/cricketMatchList.vue');
const cricketFightContent  = () => import(/* webpackChunkName: "my-market-module" */ './views/cricktFightContent.vue');
const teamBet  = () => import(/* webpackChunkName: "my-market-module" */ './views/teamBet.vue');
const cricketFightDetails  = () => import(/* webpackChunkName: "my-market-module" */ './views/cricketFightMdetails.vue');
const betWithTeam  = () => import(/* webpackChunkName: "my-market-module" */ './views/betWithTeam.vue');

const CricketFightRoutes = {
    path: '/',
    component: CricketFightModule ,
    children: [        
        
        {
            path: 'cricket-fight',
            name: 'cricketFight',
            component: cricketFightMatch,
        },
        {
            path: 'cricket-fight/:id',
            name: 'cricketFightDetails',
            component: cricketFightDetails,
        },

        {
            path: 'cricket-fight/:id/place-bet',
            name: 'bet-with-team',
            component: betWithTeam,
        },
        {
            path: 'cricket-fight/my-contests',
            name: 'cricketFightContent',
            component: cricketFightContent,
        },        

        {
            path: 'cricket-fight/my-contests/:id',
            name: 'teamBet',
            component: teamBet,
        },


    ],
}

export default CricketFightRoutes;