import axios from 'axios'
import { headers } from '@/shared/services/headers'
import { TIME_5000 } from '@/shared/constants/services-const'

const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  timeout: TIME_5000,
  headers: headers
})

instance.interceptors.response.use(function (response) {
  return Promise.resolve(response);
}, function (error) {
  if (!error?.response?.status) {
    return Promise.reject(error?.response);
  }
  switch (error.response.status) {
    case 400:
      return Promise.reject([error.response?.data?.message]);
    case 401:
      window.emitLogoutMsg.click();
      clearDataOnUnauthorised();
      return Promise.reject("Unauthorized");
    case 422:
      return Promise.reject(error.response.data.details[Object.keys(error.response.data.details)[0]]);
    default:
      return Promise.reject(error?.response);
  }
});


function clearDataOnUnauthorised() {
  window.cookies.keys().forEach(cookie => window.cookies.remove(cookie))
  window.store.dispatch('resetAllState');
  window.router.push({ name: 'Home' });
}

export default instance;