<template>
    <section class="login-now" loding="lazy">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="loginInner1">
                            <div class="log-logo">
                                <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo" class="logo-login">
                            </div>
                            <div class="featured-box-login">
                                <h1>{{ translatedLangData('register-account','Register Account')}} <i class="fas fa-hand-point-down"></i></h1>
                                <p class="whats-with"
                                    v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                    <span class="whats-with-now">{{ translatedLangData('get-your-ready-made-id-from-whatsapp',"Get your ready-made ID from whatsapp")}}</span>
                                </p>
                                <form class="form-control-head">
                                    <div class="whats-app"
                                        v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                        <div class="button-whatsapp">
                                            <button class="btn-whatsapp" @click.prevent="openWhatsApp()">
                                                <img src="@/assets/images/whatsapp.webp" alt="" />
                                                <span class="blinking">{{ translatedLangData('whatsapp-now',"WhatsApp Now")}}</span></button>
                                        </div>
                                        <h3 class="whats-with">
                                            <span class="whats-with-now">
                                                {{ translatedLangData('or-create-account-with-number',"OR create account with Number")}}
                                            </span>
                                        </h3>
                                    </div>

                                    <div role="alert" class="p-2 alert alert-danger"
                                        v-if="showValidationError && errorList.length">
                                        <ul>
                                            <li class="py-1" v-for="(error, index) in errorList" :key="index">&#x2022; {{ error }}</li>
                                        </ul>
                                    </div>

                                    <div class="get-mob">
                                        <div class="row">
                                            <div class="col-3">
                                                <div class="col-auto">
                                                    <select v-model="form.code" class="form-select"
                                                        aria-label="Default select example" :disabled="isOtpApiCalled">
                                                        <option :value="allowedCountry.phonecode"
                                                            v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                            :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-9">
                                                <div class="col-auto">
                                                    <input type="tel" class="form-control add" :class="isMobileNoValid ? '': 'border border-2 border-danger'" id="autoSizingInput" @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('MobileNo'),showWarningsAbove()" @paste="showWarningInFields('MobileNo'),showWarningsAbove()"
                                                    :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" maxlength="10" v-model="form.mobileNo" :disabled="isOtpApiCalled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mak-gin mo-number-right">
                                        <p>{{ form.mobileNo.toString().length }}/10</p>
                                        <button class="btn cmn-bdr-btn otp-btn" @click.prevent="sendOtpCall()" :class="(resendOtp && isOtpApiCalled) || !isMobileNoValid || this.form.mobileNo=='' ? 'disabled' : ''"
                                            :disabled="(resendOtp && isOtpApiCalled) || !isMobileNoValid || this.form.mobileNo==''">{{ translatedLangData('get-otp',"Get OTP")}}</button>
                                    </div>

                                    <div class="get-mob for mt-3" v-if="isOtpApiCalled">
                                        <input type="tel" class="form-control" id="inputPassword2" :class="this.isOtpApiCalled && this.form.otp=='' ? 'border border-2 border-danger': ''" @input="numberFormat('otp')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('Otp'),showWarningsAbove()"
                                        :placeholder="translatedLangData('enter-otp','Enter OTP')+'*'" v-model="form.otp" maxlength="6" />
                                        <div class="inp-img"><img src="@/assets/images/key-icon.webp" alt="" /></div>
                                    </div>

                                    <div v-if="resendOtp">
                                        <span id="resend_time" v-if="timerRunning" class="otp-countdown-text text-danger">
                                            {{ translatedLangData('resend-in',"Resend in")}} {{ formatTime(minutes) + ':' + formatTime(seconds) }}
                                            <span id="timer"></span></span>
                                        <span id="resend_button" class="otp-countdown-text" v-else>
                                            <a href="Javascript:void(0);" class="text-success resend-btn" type="button"
                                                @click.prevent="sendOtpCall()">
                                                {{ translatedLangData('send-sms-again',"SEND SMS AGAIN")}}
                                            </a>
                                        </span>
                                    </div>

                                    <div class="get-mob for mt-3">
                                        <input type="test" maxlength="25" class="form-control" id="inputPassword2" :disabled="isDisabled" @input="filterSpaces($event,'userName'),handleInputUserID($event,'userName')"
                                        :placeholder="translatedLangData('user-id','User ID')+'*'" v-model="form.userName" :class="(this.form.otp!='' && this.form.userName=='') || (!this.isUserNameValid && this.form.userName!='')  ? 'border border-2 border-danger': ''" @keyup="showWarningInFields('UserName'),showWarningsAbove()" @paste="showWarningInFields('UserName'),showWarningsAbove()"/>
                                        <div class="inp-img"><img src="@/assets/images/user-login.webp" alt="" /></div>
                                    </div>

                                    <div class="get-mob for mt-3">
                                        <input :type="passwordFieldType" maxlength="20" class="form-control" id="inputPassword4" :disabled="isDisabled" @input="filterSpaces($event,'password'),handleInputUserID($event,'password')"
                                        :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" :class="(this.form.otp!='' && this.form.password=='') || (this.form.password.length<8 && this.form.password!='') || (!/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(this.form.password) && this.form.password!='') ? 'border border-2 border-danger': ''" @keyup="showWarningInFields('PasswordField'),showWarningsAbove()" @paste="showWarningInFields('PasswordField'),showWarningsAbove()"/>
                                        <div class="inp-img" @click="togglePasswordVisibility('password')">
                                            <img v-if="passwordFieldType === 'password'" src="@/assets/images/passwordhideicon.webp" alt=""/>
                                            <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/>
                                        </div>
                                    </div>

                                    <div class="get-mob for mt-3">
                                        <input :type="confPasswordFieldType" maxlength="20" class="form-control" id="inputPassword5" :disabled="isDisabled" @input="filterSpaces($event,'confirmPassword'),handleInputUserID($event,'confirmPassword')"
                                        :placeholder="translatedLangData('confirm-password','Confirm Password')+'*'" v-model="form.confirmPassword" :class="(this.form.password && this.form.confirmPassword=='') || (this.form.password !== this.form.confirmPassword && this.form.confirmPassword!='') ? 'border border-2 border-danger': ''" @keyup="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()" @paste="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()"/>
                                        <div class="inp-img" @click="togglePasswordVisibility('confpassword')">
                                            <img v-if="confPasswordFieldType === 'password'" src="@/assets/images/passwordhideicon.webp" alt=""/>
                                            <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/>
                                        </div>
                                    </div>
                                    
                                    <div class="get-mob for mt-3">
                                        <input type="tel" class="form-control refer-code" @input="referCodeValidation"
                                            :placeholder="translatedLangData('refer-code','Refer code')" v-model="form.referCode" :disabled="checkReferUrl"/>
                                        <div class="inp-img"><img src="@/assets/images/signup-refer.webp" alt="" /></div>
                                    </div>

                                    <div class="form-group">
                                        <a href="JAvascript:void(0);" class="btn btn-submit btn-login" :class="!isFormValid ? 'disabled':''"
                                            @click="signUpCall()">
                                            {{ translatedLangData('register','Register')}}
                                            <i class="ml-2 fas fa-sign-in-alt"></i>
                                        </a>
                                    </div>

                                    <p class="whats-with-acc">{{ translatedLangData('already-have-an-account','Already have an account?')}} &nbsp;
                                        <router-link :to="{ name: 'Login' }" @click="refreshData">
                                            {{ translatedLangData('log-in','Login')}}
                                        </router-link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import api from '../services/api';
import * as apiName from '../services/urls';
import { toHandlerKey } from 'vue';
import { mapGetters } from 'vuex';

export default {
    name: "SignUp",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.cncode ? this.siteSettings.cncode : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            loadingSignUp: false,
            minutes: 2,
            seconds: 0,
            errorList: [],
            untouchedMobileNo: true,
            untouchedOtp: true,
            untouchedUserName: true,
            untouchedPasswordField: true,
            untouchedConfirmPasswordField: true,
            isDisabled:true,
            passwordFieldType: 'password',
            confPasswordFieldType: 'password',
        };
    },
    created() {
        if(this.siteSettings && this.siteSettings?.business_type == 1){
            this.$router.push({ name: 'Login' })
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        }),
        isFormValid() {
            return this.getOtp() && this.form.otp!=='' && this.isUserNameValid && this.isPasswordFieldValid && this.form.confirmPassword == this.form.password && this.form.userName;
        },
        isMobileNoValid() {
            return this.untouchedMobileNo || this.form.mobileNo.length === 10;
        },
        isUserNameValid() {
            return this.untouchedUserName || (
                this.form.userName.length >= 6 &&
                this.form.userName.length <= 25 &&
                /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{6,25}$/.test(this.form.userName)
            );
        },
        isPasswordFieldValid() {
            return this.untouchedPasswordField || (
                this.form.password.length >= 8 &&
                this.form.password.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.password)
            );
        },
        isConfirmPasswordFieldValid() {
            return this.untouchedConfirmPasswordField || (
                this.form.confirmPassword.length >= 8 &&
                this.form.confirmPassword.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.confirmPassword)
            );
        },
        checkReferUrl() {
            return (window.location.href).includes('refer_code') || this.isDisabled;
        }
    },
    methods: {
        referCodeValidation(event) {
            this.form.referCode = event.target.value.replace(/\D/g, '');
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }
            
        },
        handleInputUserID(event,prop) {
            this.form[prop] = event.target.value.replace(/[^\w]/g, '').replace(/_/g, '').slice(0, 25);
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
            event.target.value = this.form[prop].slice(0, 25);
        },
        filterSpaces(event,prop) {
            const sanitized = event.target.value.replace(/[^\w !@#$%^&*()+\-*/]/g, '');
            this.form[prop] = sanitized;
            const containsSpaces = /\s/.test(this.form[prop]);            
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },  
        showWarningsAbove(){
            this.showValidationError = false;
            this.errorList=[]
            if (!this.isMobileNoValid) {
                this.errorList.push(this.translatedLangData('mobile-number-should-be-of-10-digits','Mobile Number Should Be Of 10 Digits.'))
                this.showValidationError = true;
            }
            if (this.isOtpApiCalled && this.form.otp=='') {
                this.errorList.push(this.translatedLangData('please-enter-otp','Please Enter OTP'))
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.userName=='') {
                this.errorList.push(this.translatedLangData('please-enter-user-id','Please Enter User ID'))
                this.showValidationError = true;            
            }
            if(!this.isUserNameValid && this.form.userName!=''){
                this.errorList.push(this.translatedLangData('user-id-alphabets-numbers-and-between-6-to-25-characters','User ID Must Contain Alphabets And Numbers And Between 6 To 25 Characters'))
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.password=='') {
                this.errorList.push(this.translatedLangData('please-enter-password','Please Enter Password'))
                this.showValidationError = true;            
            } 
            if(this.form.password.length<8 && this.form.password!=''){
                this.errorList.push(this.translatedLangData('pass-min-8-max-20','Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.'));
                this.showValidationError = true;
            }
            if(!/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(this.form.password) && this.form.password!=''){
                this.errorList.push(this.translatedLangData('pass-alpha-num-1-caps','Password Must Contains Alphabets, Numbers And Atleast 1 In Capital Case.'));
                this.showValidationError = true;
            }
            if (this.form.password && this.form.confirmPassword=='') {
                this.errorList.push(this.translatedLangData('please-enter-confirm-password','Please Enter Confirm Password'))
                this.showValidationError = true;            
            }            
            if(this.form.password !== this.form.confirmPassword && this.form.confirmPassword!=''){
                this.errorList.push(this.translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.'))
                this.showValidationError = true;
            }
        },       
        showWarningInFields(prop) {
            this[`untouched${prop}`] = false
        },
        numberFormat(prop) {
            // Convert the input to a string
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            };
                this.showValidationError = false;
                this.showErrorMobile = false;
                this.passwordValidation = false;
                this.isOtpApiCalled = false;
                this.resendOtp = false;
                this.resendOtpTimer = 0;
                this.timerRunning = false;
                this.minutes = 0;
                this.seconds = 0;
                this.errorList= [];
            this.untouchedMobileNo= true;
            this.untouchedOtp= true;
            this.untouchedUserName= true;
            this.untouchedPasswordField= true;
            this.untouchedConfirmPasswordField= true;
            this.isDisabled=true;
            this.passwordFieldType= 'password';
            this.confPasswordFieldType= 'password';
        },
        openWhatsApp() {
            window.open(`//wa.me/${this.siteSettings.social_link.whatsappno1}`, '_blank');
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.errorList.push(this.translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.'));
                    this.showValidationError = true;
                }
                else {
                    this.errorList = [];
                    this.showValidationError = false;
                    this.sendOtpServiceCall();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.isDisabled = false;
                        this.showSuccessModalFunc(response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                    else if (response.status == 0) {
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                console.log("Error : ", error);
                this.showErrorModalFunc(error[0])
            });
        },
        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        signUpCall() {
            var regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/;
            this.showValidationError = false;
            this.errorList = [];
            if (!this.form.mobileNo) {
                this.errorList.push(this.translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.'))
                this.showValidationError = true;

            } else if (this.form.otp.toString().length != 6 ) {
                this.errorList.push(this.translatedLangData('otp-6-digits','OTP Should Be 6 Digits.'))
                this.showValidationError = true;

            } else if (this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                if(this.form.userName == '') {
                    this.errorList.push(this.translatedLangData('please-enter-user-id','Please Enter User ID'))
                }
                if(this.form.password == '') {
                    this.errorList.push(this.translatedLangData('please-enter-password','Please Enter Password'))
                }
                if(this.form.confirmPassword == '') {
                    this.errorList.push(this.translatedLangData('please-enter-confirm-password','Please Confirm Password'))
                }
                this.showValidationError = true;

            } else if (!regex.test(this.form.password)) {
                this.errorList.push(this.translatedLangData('pass-min-8-max-20','Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.'));
                this.errorList.push(this.translatedLangData('pass-alpha-num-1-caps','Password Must Contains Alphabets, Numbers And Atleast 1 In Capital Case.'));
                this.showValidationError = true;

            } else if (this.form.password != this.form.confirmPassword) {
                this.errorList.push(this.translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.'));
                this.showValidationError = true;
            } else{
                this.signUpServiceCall();
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            this.$store.dispatch('setGlobalLoader', true);
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.form.otp,
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.message);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$router.push({name: 'Login'})

                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },

    },
};
</script>

<style>
.form-control.refer-code:disabled {
    background-color: #b3b7b5;
}
</style>